import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Observable, from, of } from "rxjs";
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  constructor(private http: HttpClient) { }

  getHandsontableLicense(): Observable<string> {
    return this.http.get('assets/handsontable.json').pipe(
      map((data: any) => {
        if (data && data.license) {
          return data.license;
        } else {
          return environment.HANDSONTABLE_LICENSE_KEY;
        }
      }),
      catchError(() => {
        return of(environment.HANDSONTABLE_LICENSE_KEY);
      })
    );
  }
}