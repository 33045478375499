<h1>Launch calculation</h1>
<div class="container">
    <div class="loading-status" *ngIf="isSaving">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="date-selector">
        <mat-form-field>
            <mat-label>Add a closing date</mat-label>
            <input matInput [matDatepicker]="picker" (dateChange)="onDateChange($event)" #dateInput>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>  
    </div>
    <div class="years-table">
        <h3>Selected closing dates:</h3>
        <hot-table #hot [data]="dataset" [colHeaders]="true" 
            [columns]="columnSettings"
            [contextMenu]="['remove_row']"
            [licenseKey]="licenseKey"
            *ngIf="dataset.length > 0">
            <hot-column data="closing_date" title="Closing Dates"></hot-column>
        </hot-table>
    </div>
    <div class="actions-buttons" *ngIf="!isSaving && dataset.length > 0">
        <button mat-raised-button class="action-button" (click)="launchCalculation()">Launch calculation</button>
    </div>
    <div class="errors" *ngIf="errors.length > 0">
        <div *ngFor="let error of errors">{{error}}</div>
    </div>
    <div class="success" *ngIf="isSuccess === true">The calculation has been successfully launched.</div>
</div>