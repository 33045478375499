<h1>Costs management</h1>
<div class="container">
    <div class="loading-status" *ngIf="isLoading || isSaving">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="costs-table" *ngIf="!isLoading">
        <hot-table #hot [data]="dataset" [colHeaders]="true" 
            [columns]="columnSettings"
            [contextMenu]="['row_above', 'row_below', 'remove_row']"
            [licenseKey]="licenseKey"
            [afterChange]="logChanges">
        <hot-column data="year" title="Year"></hot-column>
        <hot-column data="marketAccessCost" title="Market Access Cost" type="numeric"></hot-column>
        <hot-column data="sdlDeoptimizationCosts" title="SDL Deoptimization Costs" type="numeric"></hot-column>
        <hot-column data="nonStandardProfileDeduction" title="Non-Standard Profile Deduction" type="numeric"></hot-column>
        <hot-column data="gainGreenCertificates" title="Gain Green Certificates" type="numeric"></hot-column>
        </hot-table>
    </div>
    <div class="actions-buttons" *ngIf="!isLoading && !isSaving">
        <button mat-raised-button class="action-button" (click)="updateCosts()" *ngIf="errors.length === 0">Update Costs</button>
        <button mat-raised-button class="action-button" (click)="resetCosts()">Reset Costs</button>
    </div>
    <div class="errors" *ngIf="errors.length > 0">
        <div *ngFor="let error of errors">{{error}}</div>
    </div>
</div>