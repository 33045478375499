import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common'
import { HotTableModule } from '@handsontable/angular';
import { registerAllModules } from 'handsontable/registry';
import { Observable, Subscription } from 'rxjs';
import {MatDatepickerControl, MatDatepickerInputEvent, MatDatepickerModule, MatDatepickerPanel} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button'; 
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {provideNativeDateAdapter} from '@angular/material/core';
import { ViewChild, ElementRef } from '@angular/core'

import { ClosingDates } from '../../services/ees-api.types';
import { EesApiService } from '../../services/ees-api.service';
import { LicenseService } from '../../services/license.service';
import { getApiErrorMessage } from '../../utils/api-error';

registerAllModules();

@Component({
  selector: 'app-calculation',
  standalone: true,
  imports: [CommonModule, HotTableModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatButtonModule, MatProgressBarModule],
  providers: [provideNativeDateAdapter()],
  templateUrl: './calculation.component.html',
  styleUrl: './calculation.component.css'
})
export class CalculationComponent implements OnDestroy {
  public isSaving = false;
  public isSuccess = false;
  public picker!: MatDatepickerPanel<MatDatepickerControl<any>, any, any>;
  public dataset: any[] = [];
  public calculationResult?: Observable<any>;
  public errors: string[] = [];
  public licenseKey: string | undefined = 'non-commercial-and-evaluation';
  private subscriptions: Subscription[] = [];
  @ViewChild('dateInput') dateInput?: ElementRef;

  public columnSettings = [
    {
      data: 'closing_date',
      type: 'date'
    }
  ];

  constructor(private eesApiService: EesApiService,  private licenseService: LicenseService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.licenseService.getHandsontableLicense().subscribe((licenseKey) => {
        this.licenseKey = licenseKey;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  public onDateChange(event: MatDatepickerInputEvent<Date>) {
    this.errors = [];
    this.isSuccess = false;
    const selectedDate = event.value;
    if (selectedDate === null) {
      return;
    }
    const formattedDate = selectedDate.toISOString().split('T')[0];
    this.dataset = [...this.dataset, { closing_date: formattedDate }];
    // Reset the selected date
    if (this.dateInput) {
      this.dateInput.nativeElement.value = '';
    }
  }

  public launchCalculation(): void {
    if (this.dataset.length === 0) {
      this.errors.push('Please select at least one closing date');
      return;
    }
    const closingDates: ClosingDates = { 
      closing_dates: this.dataset.map(item => item.closing_date)
    };
    this.isSuccess = false;
    this.isSaving = true;
    this.errors = [];
    this.subscriptions.push( 
      this.eesApiService.triggerCalculation(closingDates).subscribe({
        next: (result: any) => {
          this.calculationResult = result;
          this.isSaving = false;
          this.isSuccess = true;
        },
        error: (error: any) => {
          this.errors.push(`An error occurred while triggering calculation: ${getApiErrorMessage(error)}`);
          this.isSaving = false;
        }
      })
    );
  }
}
