import { Injectable } from '@angular/core';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';

const COOKIE_KEY = 'alpiqio_auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private cookieService: CookieService) { }

  public logout(): void {
    this.cookieService.delete(COOKIE_KEY);
  }

  public setToken(token: string): void {
    this.cookieService.set(COOKIE_KEY, token);
  }

  public getToken(): string | null {
    const token: string | null = this.cookieService.get(COOKIE_KEY);
    if (token && !this.isTokenExpired(token)) {
      return token;
    }
    return null;
  }

  public hasValidToken(): boolean {
    return !!this.getToken();
  }

  private isTokenExpired(token: string): boolean {
    if (!token) return true;
    const decoded: JwtPayload = jwtDecode(token);
    const expiry = decoded.exp;
    if (!expiry || Date.now() >= expiry * 1000) {
      this.logout();
      return true;
    }
    return false;
  }

  public redirectToAuth() {
    const redirectUrl = encodeURIComponent(`${window.location.origin}/login`);
    let ssoUrl = `${environment.onePassportSsoUrl}?nocookie&redirect=${redirectUrl}`;
    window.location.href = ssoUrl;
  }

}