import { Component } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { Title } from "@angular/platform-browser";
import {MatToolbarModule} from '@angular/material/toolbar'; 
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MatToolbarModule, MatButtonModule, RouterModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle("EES Selling price calculator");
  }
}
