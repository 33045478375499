import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, ParamMap } from '@angular/router';

const TOKEN_PARAM = 'alpiqio_auth';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  constructor(private route: ActivatedRoute, private authService: AuthService) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      const hasTokenParam: boolean = paramMap.has(TOKEN_PARAM);
      if (hasTokenParam) {
        const tokenParam: string = paramMap.get(TOKEN_PARAM) as string;
        this.authService.setToken(tokenParam); // setting Token as cookie
      }

      if (this.authService.hasValidToken()) {
        const url: string | null = localStorage.getItem('requested_url');
        if (url) {
          window.location.href = url;
        }
      }
    });
  }
}
