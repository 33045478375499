import { Component, OnDestroy, OnInit } from '@angular/core';
import { HotTableModule } from '@handsontable/angular';
import { registerAllModules } from 'handsontable/registry';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button'; 

import { EesApiService } from '../../services/ees-api.service';
import { CostDetailsUi, Costs } from '../../services/ees-api.types';
import { Subscription } from 'rxjs/internal/Subscription';
import { CommonModule } from '@angular/common';
import { getApiErrorMessage } from '../../utils/api-error';
import { LicenseService } from '../../services/license.service';

//register all Handsontable modules
registerAllModules();

const isYearValid = (year: any): boolean => {
  const yearInt = parseInt(year);
  return !isNaN(yearInt) && yearInt >= 2000 && yearInt < 2100;
}

const validatorYears = (value: any, callback: any) => {
  const year = parseInt(value);
  if (isYearValid(year)) {
    callback(false);
  } else {
    callback(true);
  }
}

const validatorNumeric = (value: any, callback: any) => {
  if (typeof value === 'number') {
    callback(true);
  } else {
    callback(false);
  }
}

@Component({
  selector: 'app-costs-management',
  standalone: true,
  imports: [HotTableModule, CommonModule, MatProgressBarModule, MatButtonModule],
  templateUrl: './costs-management.component.html',
  styleUrl: './costs-management.component.css'
})
export class CostsManagementComponent implements OnInit, OnDestroy {
  public costs: Costs = {};
  public dataset: CostDetailsUi[] = [];
  public isLoading = false;
  public isSaving = false;
  public errors: string[] = [];
  public licenseKey: string | undefined = 'non-commercial-and-evaluation';
  private subscriptions: Subscription[] = [];

  public columnSettings = [
    {
      data: 'year',
      required: true,
      validator: validatorYears
    },
    {
      data: 'marketAccessCost',
      required: true,
      type: 'numeric',
      validator: validatorNumeric
    },
    {
      data: 'sdlDeoptimizationCosts',
      required: true,
      type: 'numeric',
      validator: validatorNumeric
    },
    {
      data: 'nonStandardProfileDeduction',
      required: true,
      type: 'numeric',
      validator: validatorNumeric
    },
    {
      data: 'gainGreenCertificates',
      required: true,
      type: 'numeric',
      validator: validatorNumeric
    },
  ];

  constructor(private eesApiService: EesApiService, private licenseService: LicenseService) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.licenseService.getHandsontableLicense().subscribe((licenseKey) => {
        this.licenseKey = licenseKey;
      })
    );

    this.getCosts();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  public getCosts(): void {
    this.isLoading = true;
    this.isSaving = false;
    this.errors = [];
    this.subscriptions.push(
      this.eesApiService.getCosts().subscribe({
        next: (costs: Costs) => {
          this.costs = costs;
          this.dataset = Object.entries(costs).map(([year, details]) => ({ year, ...details }));
          this.isLoading = false;
        },
        error: (error: any) => {
          const errMsg: string = `An error occurred while fetching costs: ${getApiErrorMessage(error)}`;
          this.errors.push(errMsg);
          this.isLoading = false;
        }
      })
    );
  }

  public resetCosts(): void {
    this.getCosts();
  }

  public checkFields(): void {
    this.errors = [];
    const years = new Set();
    this.dataset.forEach(item => {
      // Check if all specified fields are numeric
      ['marketAccessCost', 'sdlDeoptimizationCosts', 'nonStandardProfileDeduction', 'gainGreenCertificates'].forEach(field => {
        if (typeof item[field as keyof CostDetailsUi] !== 'number') {
          this.errors.push(`${field} is not numeric (${item[field as keyof CostDetailsUi]})`);
        }
      });
  
      // Check if 'year' is a correct year and not a duplicate
      const year = item['year'];
      if (!isYearValid(year)) {
        this.errors.push(`Invalid year: ${year}`);
      } else if (years.has(year)) {
        this.errors.push(`Duplicate year: ${year}`);
      } else {
        years.add(year);
      }
    });
  }

  private reorderYears(): void {
    this.dataset.sort((a, b) => {
      try {
        const aInt = parseInt(a.year);
        const bInt = parseInt(b.year);
        return aInt - bInt;
      } catch(err) {
        return 1; // should not happen
      }
    });
  }

  public logChanges = (changes: any): void => {
    this.checkFields();
  }

  public updateCosts(): void {
    this.checkFields();
    this.reorderYears();
    if (this.errors.length === 0) {
      const updatedCosts: Costs = this.dataset.reduce((acc, cost) => {
        const { year, ...details } = cost;
        acc[year] = details;
        return acc;
      }, {} as Costs);
      this.isSaving = true;
      this.isLoading = false;
      this.subscriptions.push(
        this.eesApiService.updateCosts(updatedCosts).subscribe({
          next: () => {
            this.isSaving = false;
            this.costs = updatedCosts;
          },
          error: (error: any) => {
            const errMsg: string = `An error occurred while updating costs: ${getApiErrorMessage(error)}`;
            this.errors.push(errMsg);
            this.isSaving = false;
          }
        })
      );
    }
  }
}