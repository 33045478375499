import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, delay, of, switchMap } from "rxjs";
import { ClosingDates, Costs } from "./ees-api.types";

@Injectable({
  providedIn: 'root'
})
export class EesApiService {
  private apiUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) {
  }

  public getCosts(): Observable<Costs> {
    return this.http.get<Costs>(`${this.apiUrl}/costs`);
  }

  public updateCosts(costs: Costs): Observable<any> {
    return this.http.put(`${this.apiUrl}/costs`, costs);
  }

  public triggerCalculation(closingDates: ClosingDates): Observable<any> {
    return this.http.post(`${this.apiUrl}/trigger-calculation`, closingDates);
  }
}