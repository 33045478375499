import { ApplicationConfig } from '@angular/core';
import  {importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { MatNativeDateModule } from '@angular/material/core';

import { routes } from './app.routes';
import { AuthInterceptor } from './components/authentication/auth.interceptor';


export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    [
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    importProvidersFrom(MatNativeDateModule)
  ]
};
