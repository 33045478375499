import { ActivatedRouteSnapshot, RouterStateSnapshot, Routes } from '@angular/router';
import { CalculationComponent } from './components/calculation/calculation.component';
import { CostsManagementComponent } from './components/costs-management/costs-management.component';
import { AuthGuard } from './components/authentication/auth.guard';
import { inject } from '@angular/core';
import { LoginComponent } from './components/login/login.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/costs',
        pathMatch: 'full'
    },
    {
        path: 'login', 
        component: LoginComponent
    },
    {
        path: 'costs', 
        component: CostsManagementComponent, 
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
    },
    {
        path: 'calculation', 
        component: CalculationComponent, 
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
    }
];
